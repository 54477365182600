import React, { memo, ReactNode, useCallback, useState } from "react";

import { Button, Col, Input, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import { FormItem, useForm } from "@app/components/Common/atoms/Form/Form";
import FormModal from "@app/components/Common/atoms/FormModal/FormModal";

import useShowModal from "@app/hooks/useShowModal";

import styles from "./DeleteConfirmationModal.module.scss";
import { useAppSelector } from "@app/store/store";

export const CUSTOM_ACTION_DELETE = "delete";

export interface DeletePayloadDef {
  id: string;
  note?: string;
}

interface DeleteConfirmationModalProps {
  title?: string;
  text?: string | ReactNode;
  okText?: string;
  onConfirm: (data: DeletePayloadDef, callback: () => void) => void;
  loading?: boolean;
}

interface DeleteFieldsDef {
  note: string;
}

const DeleteConfirmationModal = ({
  title,
  text,
  okText,
  onConfirm,
  loading,
}: DeleteConfirmationModalProps) => {
  const [isValid, setStatusValid] = useState(false);
  const { isShowModal, closeModal } = useShowModal({
    customActionType: CUSTOM_ACTION_DELETE,
  });
  const { t } = useTranslation();

  const { modal } = useAppSelector(state => state.ui);
  const [form] = useForm();

  const handleClose = useCallback(() => {
    form.resetFields();
    closeModal();
  }, [closeModal, form]);

  const handleFinish = async (values: DeleteFieldsDef) => {
    if (modal?.entryId) {
      onConfirm({ id: modal.entryId, note: values.note }, handleClose);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStatusValid(Boolean(!value || (modal && modal.entryName === value)));
  };

  return (
    <FormModal
      form={form}
      title={title ?? t("default.deleteTitle")}
      visible={isShowModal}
      onClose={handleClose}
      onFinish={handleFinish}
      destroyOnClose
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={handleClose}>{t("default.cancelTitle")}</Button>
              <Button
                type="primary"
                disabled={!isValid}
                className={styles.submitButton}
                danger
                htmlType="submit"
                loading={loading}
              >
                {okText ?? t("default.delete")}
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <div className={styles.content}>
        {text}
        {!text && modal && modal.entryName && (
          <>
            <p>
              This will permanently delete the <b>{modal.entryName}</b>
              <b></b>
            </p>
            <p>To confirm, type name in the box below</p>
          </>
        )}
      </div>
      <Col span={24}>
        <FormItem name="note">
          <Input onChange={handleChange} />
        </FormItem>
      </Col>
    </FormModal>
  );
};

export default memo(DeleteConfirmationModal);
