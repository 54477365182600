import { PrivateServiceSpecificationsApi } from "@app/@generated";
import { axiosInstance } from "@app/api/api";
import { apiClientConfiguration } from "@app/constants/api.constants";

const serviceSpecificationsApi = new PrivateServiceSpecificationsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

export { serviceSpecificationsApi };
