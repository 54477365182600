import {SERVICES_TRANSLATIONS_EN} from "@app/features/services/localizations/en/translation";
import {
  SERVICE_SPECIFICATIONS_TRANSLATIONS_EN
} from "@app/features/services-specifications/localizations/en/translation";
import {SERVICE_APPS_TRANSLATIONS_EN} from "@app/features/consumerServiceApps/localizations/en/translation";

export const TRANSLATIONS_COMMON_EN = {
  "default.signOut": "Log out",
  "default.columnActions": "Actions",
  "default.reset": "Reset",
  "default.apply": "Apply",
  "default.columnId": "ID",
  "default.edit": "Edit",
  "default.view": "View",
  "default.duplicate": "Duplicate",
  "default.description": "Description",
  "default.descriptionPlaceholder": "Type Description here...",
  "default.apiSpec": "API Specification",
  "default.delete": "Delete",
  "default.deleteTitle": "Delete confirmation",
  "default.more": "View more",
  "default.cancelTitle": "Cancel",
  "default.saveTitle": "Save",
  "default.confirmTitle": "Confirm",
  "default.minimumCharacters": "Minimum characters",
  "default.publicTitle": "Public",
  "default.privateTitle": "Private",
  "default.privacy": "Privacy",
  "default.uploadFile": "Upload",
  "default.uploadFileHelper": "Click for uploading",
  "default.searchService": "Select Service",
  "default.status": "Status",
  "default.method": "Method",
  "default.signIn": "Log in",

  // Subscribe
  "default.subscribe": "Subscribe",
  "default.unsubscribe": "Unsubscribe",
  "default.subscribeNotificationTitle": "Subscribe",
  "default.subscribeNotification": "You have been subscribed",
  "default.unsubscribeNotificationTitle": "Unsubscribe",
  "default.unsubscribeNotification": "You have been unsubscribe",
  "default.unsubscribeChangesTitle": "Unsubscribe",
  "default.unsubscribeChangesText": "Are you sure you want to unsubscribe?",

  // Errors pages
  "default.error": "Error",
  "default.notFoundTitle": "404",
  "default.notFoundText": "Page not found",
  "default.notFoundBackHomeButton": "Back to homepage",
};

export const TRANSLATIONS_EN = {
  ...TRANSLATIONS_COMMON_EN,
  ...SERVICES_TRANSLATIONS_EN,
  ...SERVICE_SPECIFICATIONS_TRANSLATIONS_EN,
  ...SERVICE_APPS_TRANSLATIONS_EN,
};
