import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";
import { DiagramWrapper } from "@app/components/DiagramWrapper/DiagramWrapper";

import { useTranslation } from "react-i18next";
import {
  Permission,
  PermissionEnum,
} from "@app/features/permissions/permissions";
import Button from "@app/components/Common/atoms/Button/Button";
import { ServiceSpecificationsRoutesEnum } from "@app/features/services-specifications";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { serviceSpecificationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import { DeserializeData } from "@app/utils/common";
import {
  PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum,
  PrivateServiceSpecificationApiOperationLinkUnidentifiedDef,
  PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum,
  PrivateServiceSpecificationAttributesDef,
  ServiceSpecificationApiOperationLinkPositionEnum,
  ServiceSpecificationSecurityLevelEnum,
} from "@app/@generated";
import { useEffect, useState } from "react";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import OperationsSidebar from "@app/features/services-specifications/components/OperationsSidebar/OperationsSidebar";
import {
  LinkOperationType,
  Operation,
  OperationsItemType,
  OperationsType,
} from "@app/types/api.types";
import {
  deserializeOperations,
  deserializeOperationsLinks,
  deserializeUsedLinks,
} from "@app/utils/operationLinks";
import { logger } from "@app/utils/logger";

const OperationsServiceSpecification = () => {
  const [operationsLinks, setOperationsLinks] =
    useState<null | Array<LinkOperationType>>(null);
  const [operations, setOperations] =
    useState<OperationsType<Operation> | null>(null);
  const [usedOperations, setUsedOperations] = useState<Array<string>>([]);

  const { t } = useTranslation();
  const [specification, setSpecification] =
    useState<null | PrivateServiceSpecificationAttributesDef>(null);

  const [countOperation, setCountOperation] = useState<number>(0);

  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const { id }: { id: string } = useParams();

  const getSpecification = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdGet(
          {
            serviceSpecificationId: id,
          }
        );
      DeserializeData(data).then(res => {
        const is_public_level =
          res.securityLevel ===
          ServiceSpecificationSecurityLevelEnum.PUBLIC.toUpperCase();
        setSpecification({ ...res, is_public_level: is_public_level });
      });
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getOperations = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdApiOperationsGet(
          {
            serviceSpecificationId: id,
          }
        );

      const used = deserializeUsedLinks(data.data);
      const operations = deserializeOperations(data.data);

      setOperations(operations);
      setUsedOperations(used);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getOperationsLinks = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(
          {
            serviceSpecificationId: id,
          }
        );

      const links = deserializeOperationsLinks(data.data);

      setOperationsLinks(links);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveOperationsLinks = async (params: any) => {
    try {
      setLoadingStatus(true);

      await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(
        {
          serviceSpecificationId: id,
          privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef:
            params,
        }
      );
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const saveOperations = async (
    apiOperationId: string,
    x: number | null = null,
    y: number | null = null
  ) => {
    try {
      const params = {
        data: {
          type: PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum.SERVICE_SPECIFICATION_API_OPERATIONS,
          id: apiOperationId,
          attributes: {
            xCoordinate: x,
            yCoordinate: y,
          },
        },
      };

      await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(
        {
          serviceSpecificationId: id,
          apiOperationId: apiOperationId,
          inlineObjectDef: params,
        }
      );
    } catch (error: unknown) {
      logger.error(error);
    }
  };

  const handleSave = () => {
    const data: Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef> =
      [];
    operationsLinks?.forEach(item => {
      const operation: PrivateServiceSpecificationApiOperationLinkUnidentifiedDef =
        {
          type: PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum.SERVICE_SPECIFICATION_API_OPERATION_LINKS,
          attributes: {
            outwardLinkPosition:
              item.outwardLinkPosition ||
              ServiceSpecificationApiOperationLinkPositionEnum.TOP,
            inwardLinkPosition:
              item.inwardLinkPosition ||
              ServiceSpecificationApiOperationLinkPositionEnum.BOTTOM,
          },
          relationships: {
            outwardApiOperation: {
              data: {
                id: item.outwardApiOperation,
                type: PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum.SERVICE_SPECIFICATION_API_OPERATIONS,
              },
            },
            inwardApiOperation: {
              data: {
                type: PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum.SERVICE_SPECIFICATION_API_OPERATIONS,
                id: item.inwardApiOperation,
              },
            },
          },
        };
      data.push(operation);
    });

    saveOperationsLinks({
      data: data,
    });
  };

  useEffect(() => {
    getSpecification();
    getOperations();
    getOperationsLinks();
  }, [id]);

  const handleAdd = (operation: OperationsItemType) => {
    if (!usedOperations.includes(operation.id)) {
      setUsedOperations([...usedOperations, operation.id]);
      saveOperations(
        operation.id,
        countOperation * 10 + 1,
        countOperation * 10 + 1
      );
      setCountOperation(prevState => prevState + 1);
      if (operations) {
        setOperations({
          ...operations,
          [operation.id]: {
            ...operations[operation.id],
            xCoordinate: Number(countOperation * 10 + 1),
            yCoordinate: countOperation * 10 + 1,
          },
        });
      }
    }
  };

  const handleDelete = (id: string) => {
    const filtered = usedOperations.filter(item => item !== id);
    saveOperations(id);
    setUsedOperations(filtered);
    if (operationsLinks) {
      const filteredLinks = operationsLinks.filter(
        item =>
          item.outwardApiOperation !== id && item.inwardApiOperation !== id
      );
      setOperationsLinks(filteredLinks);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDeleteLine = (line: any) => {
    if (operationsLinks) {
      const filteredLinks = operationsLinks.filter(item => {
        return !(
          item.outwardApiOperation === line.outwardApiOperation &&
          item.inwardApiOperation === line.inwardApiOperation
        );
      });
      setOperationsLinks(filteredLinks);
    }
  };
  const handleNewLine = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    points: Array<any>,
    startID: string,
    stopID: string,
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum,
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum
  ) => {
    if (operationsLinks) {
      const filteredLinks = operationsLinks.filter(item => {
        return (
          (item.outwardApiOperation === startID &&
            item.inwardApiOperation === stopID) ||
          (item.outwardApiOperation === stopID &&
            item.inwardApiOperation === startID)
        );
      });

      if (filteredLinks.length === 0) {
        setOperationsLinks([
          ...operationsLinks,
          {
            inwardApiOperation: stopID,
            outwardApiOperation: startID,
            outwardLinkPosition,
            inwardLinkPosition,
          },
        ]);
      }
    }
  };

  const handleChangePosition = (
    id: string,
    xCoordinate?: number,
    yCoordinate?: number
  ) => {
    if (operations) {
      setOperations({
        ...operations,
        [id]: {
          ...operations[id],
          xCoordinate,
          yCoordinate,
        },
      });
    }

    setCountOperation(0);
  };

  return (
    <ContentLayout
      sidebar={
        <OperationsSidebar
          onAdd={handleAdd}
          operations={operations}
          isLoading={isLoading}
          usedOperations={usedOperations}
        />
      }
      breadcrumbs={[
        {
          path: ServiceSpecificationsRoutesEnum.SERVICE_SPECIFICATIONS,
          breadcrumbName: t("serviceSpecifications.title"),
        },
        {
          breadcrumbName: specification?.title || "",
        },
        {
          breadcrumbName: t("serviceSpecificationsAPIOperations.title"),
        },
      ]}
      header={{
        title: t("serviceSpecificationsAPIOperationsEdit.title"),

        extra: [
          <Permission
            requiredPermissions={[PermissionEnum.SERVICE_SPECIFICATIONS_EDIT]}
            key="edit"
          >
            <Button type="primary" onClick={handleSave}>
              {t("default.saveTitle")}
            </Button>
          </Permission>,
        ],
      }}
    >
      <div id="wrap-api-operations">
        {isLoading && <LoadingSpinner />}
        {!isLoading && specification && operations && operationsLinks && (
          <DiagramWrapper
            nodes={operations}
            nodesUsed={usedOperations}
            isEditable={true}
            operationsLinks={operationsLinks}
            container="wrap-api-operations"
            onDelete={handleDelete}
            onDeleteLine={handleDeleteLine}
            onAddLine={handleNewLine}
            onChangePosition={handleChangePosition}
            onSavePosition={saveOperations}
          />
        )}
      </div>
    </ContentLayout>
  );
};

export default OperationsServiceSpecification;
