import { PublicServiceSpecificationsApi } from "@app/@generated";
import { axiosInstance } from "@app/api/api";
import { apiClientConfiguration } from "@app/constants/api.constants";

const servicesApi = new PublicServiceSpecificationsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

export { servicesApi };
