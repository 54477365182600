export const PROJECT_TITLE = "Apigale";

export enum API_EDITOR_MODE {
  EDITOR = "editor",
  PREVIEW = "preview",
  ALL = "all",
}

export enum UserRoleEnum {
  GUEST = "guest",
  FULL = "full",
}

export const MAX_PATH_LENGTH = 13;

export const IMAGE_TRASH_URL = `${window.location.origin}/images/bin.png`;
