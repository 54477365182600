/* tslint:disable */
/* eslint-disable */
/**
 * Apigale RESTful API
 * **Apigale ASM - API Specification Management System.** </br> It\'s an API sharing and collaboration platform, available for API **Providers** and **Consumers**.</br></br> For the **API Providers** it allows to:</br> - create a Catalog of API Specifications</br> - define available Environments</br> - share APIs publicly or with specific Consumers</br> - view API usage logs</br></br> For the **API Consumers** it allows to:</br> - navigate the Catalog of API Specifications</br> - define Applications to use provided APIs, map them to available Environments</br> - view API usage logs</br></br></br> You can find out more about Apigale at [https://apigale.com](https://apigale.com). 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: apiteam@apigale.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountIdentifierObjectAllOfDef
 */
export interface AccountIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectAllOfDef
     */
    type?: AccountIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountIdentifierObjectAllOfDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface AccountIdentifierObjectDef
 */
export interface AccountIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectDef
     */
    type?: AccountIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountIdentifierObjectDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface BaseObjectAllOfDef
 */
export interface BaseObjectAllOfDef {
    /**
     * 
     * @type {BaseObjectAttributesDef}
     * @memberof BaseObjectAllOfDef
     */
    attributes: BaseObjectAttributesDef;
    /**
     * 
     * @type {object}
     * @memberof BaseObjectAllOfDef
     */
    relationships: object;
}
/**
 * 
 * @export
 * @interface BaseObjectAttributesDef
 */
export interface BaseObjectAttributesDef {
    /**
     * Object name. Can be used instread of `id` to uniquely identify the object within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    name: string;
    /**
     * Object title, or customer-facing name; to provide the full name of the object.
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    title?: string | null;
    /**
     * Object description; to provide a detailed information about the object.
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface BaseObjectDef
 */
export interface BaseObjectDef {
    /**
     * 
     * @type {BaseObjectAttributesDef}
     * @memberof BaseObjectDef
     */
    attributes: BaseObjectAttributesDef;
    /**
     * 
     * @type {object}
     * @memberof BaseObjectDef
     */
    relationships: object;
}
/**
 * 
 * @export
 * @interface BasicErrorObjectDef
 */
export interface BasicErrorObjectDef {
    /**
     * 
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof BasicErrorObjectDef
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof BasicErrorObjectDef
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface CategoryAllOfDef
 */
export interface CategoryAllOfDef {
    /**
     * 
     * @type {CategoryAttributesDef}
     * @memberof CategoryAllOfDef
     */
    attributes?: CategoryAttributesDef;
}
/**
 * 
 * @export
 * @interface CategoryAttributesDef
 */
export interface CategoryAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryAttributesDef
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CategoryBodyDef
 */
export interface CategoryBodyDef {
    /**
     * 
     * @type {CategoryDef}
     * @memberof CategoryBodyDef
     */
    data?: CategoryDef;
}
/**
 * 
 * @export
 * @interface CategoryDef
 */
export interface CategoryDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryDef
     */
    type?: CategoryDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryDef
     */
    id?: string;
    /**
     * 
     * @type {CategoryAttributesDef}
     * @memberof CategoryDef
     */
    attributes?: CategoryAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryIdentifierObjectAllOfDef
 */
export interface CategoryIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectAllOfDef
     */
    type?: CategoryIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryIdentifierObjectAllOfDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryIdentifierObjectDef
 */
export interface CategoryIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectDef
     */
    type?: CategoryIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryIdentifierObjectDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryListResponseBodyAllOfDef
 */
export interface CategoryListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<CategoryDef>}
     * @memberof CategoryListResponseBodyAllOfDef
     */
    data?: Array<CategoryDef>;
}
/**
 * 
 * @export
 * @interface CategoryListResponseBodyDef
 */
export interface CategoryListResponseBodyDef {
    /**
     * 
     * @type {Array<CategoryDef>}
     * @memberof CategoryListResponseBodyDef
     */
    data?: Array<CategoryDef>;
}
/**
 * 
 * @export
 * @interface CategoryResponseBodyDef
 */
export interface CategoryResponseBodyDef {
    /**
     * 
     * @type {CategoryDef}
     * @memberof CategoryResponseBodyDef
     */
    data?: CategoryDef;
}
/**
 * 
 * @export
 * @interface Error400Def
 */
export interface Error400Def {
    /**
     * 
     * @type {string}
     * @memberof Error400Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error400Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error400Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error400Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error400Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error400Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error401Def
 */
export interface Error401Def {
    /**
     * 
     * @type {string}
     * @memberof Error401Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error401Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error401Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error401Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error401Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error401Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error403Def
 */
export interface Error403Def {
    /**
     * 
     * @type {string}
     * @memberof Error403Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error403Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error403Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error403Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error403Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error403Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error404Def
 */
export interface Error404Def {
    /**
     * 
     * @type {string}
     * @memberof Error404Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error404Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error404Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error404Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error404Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error404Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error406Def
 */
export interface Error406Def {
    /**
     * 
     * @type {string}
     * @memberof Error406Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error406Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error406Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error406Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error406Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error406Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error409Def
 */
export interface Error409Def {
    /**
     * 
     * @type {string}
     * @memberof Error409Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error409Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error409Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error409Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error409Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error409Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error411Def
 */
export interface Error411Def {
    /**
     * 
     * @type {string}
     * @memberof Error411Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error411Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error411Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error411Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error411Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error411Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error413Def
 */
export interface Error413Def {
    /**
     * 
     * @type {string}
     * @memberof Error413Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error413Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error413Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error413Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error413Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error413Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error415Def
 */
export interface Error415Def {
    /**
     * 
     * @type {string}
     * @memberof Error415Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error415Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error415Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error415Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error415Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error415Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error422Def
 */
export interface Error422Def {
    /**
     * 
     * @type {string}
     * @memberof Error422Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error422Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error422Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error422Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error422Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error422Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error429Def
 */
export interface Error429Def {
    /**
     * 
     * @type {string}
     * @memberof Error429Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error429Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error429Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error429Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error429Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error429Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface ErrorObjectsDef
 */
export interface ErrorObjectsDef {
    /**
     * 
     * @type {Array<Error400Def | Error401Def | Error403Def | Error404Def | Error409Def | Error422Def | Error429Def>}
     * @memberof ErrorObjectsDef
     */
    errors?: Array<Error400Def | Error401Def | Error403Def | Error404Def | Error409Def | Error422Def | Error429Def>;
}
/**
 * 
 * @export
 * @interface InlineObjectDef
 */
export interface InlineObjectDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationIdentifierObjectDef & object}
     * @memberof InlineObjectDef
     */
    data?: PrivateServiceSpecificationApiOperationIdentifierObjectDef & object;
}
/**
 * 
 * @export
 * @interface MediaDef
 */
export interface MediaDef {
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MediaResponseBodyDef
 */
export interface MediaResponseBodyDef {
    /**
     * 
     * @type {MediaDef}
     * @memberof MediaResponseBodyDef
     */
    data?: MediaDef;
}
/**
 * 
 * @export
 * @interface PaginationLinksDef
 */
export interface PaginationLinksDef {
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    prev?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    last?: string;
}
/**
 * 
 * @export
 * @interface PaginationMetaDef
 */
export interface PaginationMetaDef {
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    to?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginationMetaDef
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    lastPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface PrivateAccountAllOfDef
 */
export interface PrivateAccountAllOfDef {
    /**
     * 
     * @type {PrivateAccountAttributesDef}
     * @memberof PrivateAccountAllOfDef
     */
    attributes?: PrivateAccountAttributesDef;
}
/**
 * 
 * @export
 * @interface PrivateAccountAttributesDef
 */
export interface PrivateAccountAttributesDef {
    /**
     * Account name. Can be used instread of `id` to uniquely identify the Account. </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    name: string;
    /**
     * Account title, or customer-facing name; to provide the full name of the Account.
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    title?: string;
    /**
     * Account description; to provide a detailed information about the Account.
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateAccountBodyDef
 */
export interface PrivateAccountBodyDef {
    /**
     * 
     * @type {PrivateAccountDef}
     * @memberof PrivateAccountBodyDef
     */
    data?: PrivateAccountDef;
}
/**
 * 
 * @export
 * @interface PrivateAccountDef
 */
export interface PrivateAccountDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountDef
     */
    type?: PrivateAccountDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateAccountAttributesDef}
     * @memberof PrivateAccountDef
     */
    attributes?: PrivateAccountAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateAccountDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface PrivateAccountRelationshipsDef
 */
export interface PrivateAccountRelationshipsDef {
    /**
     * 
     * @type {PrivateAccountRelationshipsOwnerUserDef}
     * @memberof PrivateAccountRelationshipsDef
     */
    ownerUser?: PrivateAccountRelationshipsOwnerUserDef;
}
/**
 * 
 * @export
 * @interface PrivateAccountRelationshipsOwnerUserDef
 */
export interface PrivateAccountRelationshipsOwnerUserDef {
    /**
     * 
     * @type {object}
     * @memberof PrivateAccountRelationshipsOwnerUserDef
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface PrivateAccountResponseBodyDef
 */
export interface PrivateAccountResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountResponseBodyDef
     */
    type?: PrivateAccountResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateAccountAttributesDef}
     * @memberof PrivateAccountResponseBodyDef
     */
    attributes?: PrivateAccountAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateAccountResponseBodyDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppAllOfDef
 */
export interface PrivateConsumerServiceAppAllOfDef {
    /**
     * 
     * @type {PrivateConsumerServiceAppAttributesDef}
     * @memberof PrivateConsumerServiceAppAllOfDef
     */
    attributes?: PrivateConsumerServiceAppAttributesDef;
    /**
     * 
     * @type {PrivateConsumerServiceAppRelationshipsDef}
     * @memberof PrivateConsumerServiceAppAllOfDef
     */
    relationships?: PrivateConsumerServiceAppRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppAttributesDef
 */
export interface PrivateConsumerServiceAppAttributesDef {
    /**
     * App name. Can be used instread of `id` to uniquely identify the App within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateConsumerServiceAppAttributesDef
     */
    name: string;
    /**
     * App title; to provide the full name of the App.
     * @type {string}
     * @memberof PrivateConsumerServiceAppAttributesDef
     */
    title?: string | null;
    /**
     * App description; to provide a detailed information about the App.
     * @type {string}
     * @memberof PrivateConsumerServiceAppAttributesDef
     */
    description?: string | null;
    /**
     * API key to be used for this App.
     * @type {string}
     * @memberof PrivateConsumerServiceAppAttributesDef
     */
    apiKey?: string | null;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppBodyDef
 */
export interface PrivateConsumerServiceAppBodyDef {
    /**
     * 
     * @type {PrivateConsumerServiceAppDef}
     * @memberof PrivateConsumerServiceAppBodyDef
     */
    data: PrivateConsumerServiceAppDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppDef
 */
export interface PrivateConsumerServiceAppDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppDef
     */
    type?: PrivateConsumerServiceAppDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateConsumerServiceAppAttributesDef}
     * @memberof PrivateConsumerServiceAppDef
     */
    attributes?: PrivateConsumerServiceAppAttributesDef;
    /**
     * 
     * @type {PrivateConsumerServiceAppRelationshipsDef}
     * @memberof PrivateConsumerServiceAppDef
     */
    relationships?: PrivateConsumerServiceAppRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceAppDefTypeEnum {
    CONSUMER_SERVICE_APPS = 'consumer-service-apps'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppIdentifierObjectAllOfDef
 */
export interface PrivateConsumerServiceAppIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppIdentifierObjectAllOfDef
     */
    type?: PrivateConsumerServiceAppIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceAppIdentifierObjectAllOfDefTypeEnum {
    CONSUMER_SERVICE_APPS = 'consumer-service-apps'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppIdentifierObjectDef
 */
export interface PrivateConsumerServiceAppIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppIdentifierObjectDef
     */
    type?: PrivateConsumerServiceAppIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceAppIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceAppIdentifierObjectDefTypeEnum {
    CONSUMER_SERVICE_APPS = 'consumer-service-apps'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppRelationshipsDef
 */
export interface PrivateConsumerServiceAppRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateConsumerServiceAppRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
    /**
     * 
     * @type {PrivateConsumerServiceAppRelationshipsServiceEnvironmentsDef}
     * @memberof PrivateConsumerServiceAppRelationshipsDef
     */
    serviceEnvironments?: PrivateConsumerServiceAppRelationshipsServiceEnvironmentsDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppRelationshipsServiceEnvironmentsDef
 */
export interface PrivateConsumerServiceAppRelationshipsServiceEnvironmentsDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationEnvironmentIdentifierObjectDef>}
     * @memberof PrivateConsumerServiceAppRelationshipsServiceEnvironmentsDef
     */
    data?: Array<PublicServiceSpecificationEnvironmentIdentifierObjectDef>;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppResponseBodyDef
 */
export interface PrivateConsumerServiceAppResponseBodyDef {
    /**
     * 
     * @type {PrivateConsumerServiceAppDef}
     * @memberof PrivateConsumerServiceAppResponseBodyDef
     */
    data: PrivateConsumerServiceAppDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppsListResponseBodyAllOfDef
 */
export interface PrivateConsumerServiceAppsListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateConsumerServiceAppDef>}
     * @memberof PrivateConsumerServiceAppsListResponseBodyAllOfDef
     */
    data: Array<PrivateConsumerServiceAppDef>;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceAppsListResponseBodyDef
 */
export interface PrivateConsumerServiceAppsListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateConsumerServiceAppDef>}
     * @memberof PrivateConsumerServiceAppsListResponseBodyDef
     */
    data: Array<PrivateConsumerServiceAppDef>;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventAllOfDef
 */
export interface PrivateConsumerServiceEventAllOfDef {
    /**
     * 
     * @type {PrivateConsumerServiceEventAttributesDef}
     * @memberof PrivateConsumerServiceEventAllOfDef
     */
    attributes?: PrivateConsumerServiceEventAttributesDef;
    /**
     * 
     * @type {PrivateConsumerServiceEventRelationshipsDef}
     * @memberof PrivateConsumerServiceEventAllOfDef
     */
    relationships?: PrivateConsumerServiceEventRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventAttributesDef
 */
export interface PrivateConsumerServiceEventAttributesDef {
    /**
     * Type of the Event: CALL, REFILL, WITHDRAWAL, ADJUSTMENT.
     * @type {string}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    recordType: PrivateConsumerServiceEventAttributesDefRecordTypeEnum;
    /**
     * IP Address, from which the Event was initiated.
     * @type {string}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    consGwCallingIp?: string;
    /**
     * Hostname which was called.
     * @type {string}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    consGwCalledHost?: string;
    /**
     * Date and Time what the Event was initiated.
     * @type {string}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    consGwRequestTimestamp?: string;
    /**
     * Date and Time what the response was provided.
     * @type {string}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    consGwResponseTimestamp?: string;
    /**
     * Monetary amount a Consumer is charged for using a Service.
     * @type {number}
     * @memberof PrivateConsumerServiceEventAttributesDef
     */
    consChargedAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceEventAttributesDefRecordTypeEnum {
    CALL = 'CALL',
    REFILL = 'REFILL',
    WITHDRAWAL = 'WITHDRAWAL',
    ADJUSTMENT = 'ADJUSTMENT'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventBodyDef
 */
export interface PrivateConsumerServiceEventBodyDef {
    /**
     * 
     * @type {PrivateConsumerServiceEventDef}
     * @memberof PrivateConsumerServiceEventBodyDef
     */
    data: PrivateConsumerServiceEventDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventDef
 */
export interface PrivateConsumerServiceEventDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventDef
     */
    type?: PrivateConsumerServiceEventDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateConsumerServiceEventAttributesDef}
     * @memberof PrivateConsumerServiceEventDef
     */
    attributes?: PrivateConsumerServiceEventAttributesDef;
    /**
     * 
     * @type {PrivateConsumerServiceEventRelationshipsDef}
     * @memberof PrivateConsumerServiceEventDef
     */
    relationships?: PrivateConsumerServiceEventRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceEventDefTypeEnum {
    CONSUMER_SERVICE_EVENTS = 'consumer-service-events'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventIdentifierObjectAllOfDef
 */
export interface PrivateConsumerServiceEventIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventIdentifierObjectAllOfDef
     */
    type?: PrivateConsumerServiceEventIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceEventIdentifierObjectAllOfDefTypeEnum {
    CONSUMER_SERVICE_EVENTS = 'consumer-service-events'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventIdentifierObjectDef
 */
export interface PrivateConsumerServiceEventIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventIdentifierObjectDef
     */
    type?: PrivateConsumerServiceEventIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateConsumerServiceEventIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateConsumerServiceEventIdentifierObjectDefTypeEnum {
    CONSUMER_SERVICE_EVENTS = 'consumer-service-events'
}

/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventListResponseBodyAllOfDef
 */
export interface PrivateConsumerServiceEventListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateConsumerServiceEventDef>}
     * @memberof PrivateConsumerServiceEventListResponseBodyAllOfDef
     */
    data: Array<PrivateConsumerServiceEventDef>;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventListResponseBodyDef
 */
export interface PrivateConsumerServiceEventListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateConsumerServiceEventDef>}
     * @memberof PrivateConsumerServiceEventListResponseBodyDef
     */
    data: Array<PrivateConsumerServiceEventDef>;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventRelationshipsConsumerServiceAppDef
 */
export interface PrivateConsumerServiceEventRelationshipsConsumerServiceAppDef {
    /**
     * 
     * @type {PrivateConsumerServiceAppIdentifierObjectDef}
     * @memberof PrivateConsumerServiceEventRelationshipsConsumerServiceAppDef
     */
    data?: PrivateConsumerServiceAppIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventRelationshipsDef
 */
export interface PrivateConsumerServiceEventRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateConsumerServiceEventRelationshipsDef
     */
    provider: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsServiceDef}
     * @memberof PrivateConsumerServiceEventRelationshipsDef
     */
    service: PublicServiceSpecificationApiOperationRelationshipsServiceDef;
    /**
     * 
     * @type {PrivateConsumerServiceEventRelationshipsServiceEnvironmentDef}
     * @memberof PrivateConsumerServiceEventRelationshipsDef
     */
    serviceEnvironment: PrivateConsumerServiceEventRelationshipsServiceEnvironmentDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateConsumerServiceEventRelationshipsDef
     */
    consumer: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
    /**
     * 
     * @type {PrivateConsumerServiceEventRelationshipsConsumerServiceAppDef}
     * @memberof PrivateConsumerServiceEventRelationshipsDef
     */
    consumerServiceApp: PrivateConsumerServiceEventRelationshipsConsumerServiceAppDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventRelationshipsServiceEnvironmentDef
 */
export interface PrivateConsumerServiceEventRelationshipsServiceEnvironmentDef {
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentIdentifierObjectDef}
     * @memberof PrivateConsumerServiceEventRelationshipsServiceEnvironmentDef
     */
    data?: PublicServiceSpecificationEnvironmentIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateConsumerServiceEventResponseBodyDef
 */
export interface PrivateConsumerServiceEventResponseBodyDef {
    /**
     * 
     * @type {PrivateConsumerServiceEventDef}
     * @memberof PrivateConsumerServiceEventResponseBodyDef
     */
    data: PrivateConsumerServiceEventDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventAllOfDef
 */
export interface PrivateProviderServiceEventAllOfDef {
    /**
     * 
     * @type {PrivateProviderServiceEventAttributesDef}
     * @memberof PrivateProviderServiceEventAllOfDef
     */
    attributes?: PrivateProviderServiceEventAttributesDef;
    /**
     * 
     * @type {PrivateProviderServiceEventRelationshipsDef}
     * @memberof PrivateProviderServiceEventAllOfDef
     */
    relationships?: PrivateProviderServiceEventRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventAttributesDef
 */
export interface PrivateProviderServiceEventAttributesDef {
    /**
     * Type of the Event: CALL, REFILL, WITHDRAWAL, ADJUSTMENT.
     * @type {string}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    record_type: PrivateProviderServiceEventAttributesDefRecordTypeEnum;
    /**
     * Hostname which was called.
     * @type {string}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    gwProvCalledHost?: string;
    /**
     * Upstream IP Address to which the call was redirected.
     * @type {string}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    gwOrovCalledIp?: string;
    /**
     * Date and Time what the Event was initiated.
     * @type {string}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    gwProvRequestTimestamp?: string;
    /**
     * Date and Time what the response was provided.
     * @type {string}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    gwProvResponseTimestamp?: string;
    /**
     * Monetary amount a Provide is accrued for using a Service.
     * @type {number}
     * @memberof PrivateProviderServiceEventAttributesDef
     */
    provAccruedAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateProviderServiceEventAttributesDefRecordTypeEnum {
    CALL = 'CALL',
    REFILL = 'REFILL',
    WITHDRAWAL = 'WITHDRAWAL',
    ADJUSTMENT = 'ADJUSTMENT'
}

/**
 * 
 * @export
 * @interface PrivateProviderServiceEventBodyDef
 */
export interface PrivateProviderServiceEventBodyDef {
    /**
     * 
     * @type {PrivateProviderServiceEventDef}
     * @memberof PrivateProviderServiceEventBodyDef
     */
    data: PrivateProviderServiceEventDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventDef
 */
export interface PrivateProviderServiceEventDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventDef
     */
    type?: PrivateProviderServiceEventDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateProviderServiceEventAttributesDef}
     * @memberof PrivateProviderServiceEventDef
     */
    attributes?: PrivateProviderServiceEventAttributesDef;
    /**
     * 
     * @type {PrivateProviderServiceEventRelationshipsDef}
     * @memberof PrivateProviderServiceEventDef
     */
    relationships?: PrivateProviderServiceEventRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateProviderServiceEventDefTypeEnum {
    PROVIDER_SERVICE_EVENTS = 'provider-service-events'
}

/**
 * 
 * @export
 * @interface PrivateProviderServiceEventIdentifierObjectAllOfDef
 */
export interface PrivateProviderServiceEventIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventIdentifierObjectAllOfDef
     */
    type?: PrivateProviderServiceEventIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateProviderServiceEventIdentifierObjectAllOfDefTypeEnum {
    PROVIDER_SERVICE_EVENTS = 'provider-service-events'
}

/**
 * 
 * @export
 * @interface PrivateProviderServiceEventIdentifierObjectDef
 */
export interface PrivateProviderServiceEventIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventIdentifierObjectDef
     */
    type?: PrivateProviderServiceEventIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateProviderServiceEventIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateProviderServiceEventIdentifierObjectDefTypeEnum {
    PROVIDER_SERVICE_EVENTS = 'provider-service-events'
}

/**
 * 
 * @export
 * @interface PrivateProviderServiceEventRelationshipsDef
 */
export interface PrivateProviderServiceEventRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef}
     * @memberof PrivateProviderServiceEventRelationshipsDef
     */
    serviceSpecification: PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef;
    /**
     * 
     * @type {PrivateProviderServiceEventRelationshipsServiceSpecificationEnvironmentDef}
     * @memberof PrivateProviderServiceEventRelationshipsDef
     */
    serviceSpecificationEnvironment: PrivateProviderServiceEventRelationshipsServiceSpecificationEnvironmentDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateProviderServiceEventRelationshipsDef
     */
    consumer: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventRelationshipsServiceSpecificationEnvironmentDef
 */
export interface PrivateProviderServiceEventRelationshipsServiceSpecificationEnvironmentDef {
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentIdentifierObjectDef}
     * @memberof PrivateProviderServiceEventRelationshipsServiceSpecificationEnvironmentDef
     */
    data?: PrivateServiceSpecificationEnvironmentIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventResponseBodyDef
 */
export interface PrivateProviderServiceEventResponseBodyDef {
    /**
     * 
     * @type {PrivateProviderServiceEventDef}
     * @memberof PrivateProviderServiceEventResponseBodyDef
     */
    data: PrivateProviderServiceEventDef;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventsListResponseBodyAllOfDef
 */
export interface PrivateProviderServiceEventsListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateProviderServiceEventDef>}
     * @memberof PrivateProviderServiceEventsListResponseBodyAllOfDef
     */
    data: Array<PrivateProviderServiceEventDef>;
}
/**
 * 
 * @export
 * @interface PrivateProviderServiceEventsListResponseBodyDef
 */
export interface PrivateProviderServiceEventsListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateProviderServiceEventDef>}
     * @memberof PrivateProviderServiceEventsListResponseBodyDef
     */
    data: Array<PrivateProviderServiceEventDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationAllOfDef
 */
export interface PrivateServiceSpecificationAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationAllOfDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationAllOfDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationAllOfDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationAllOfDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationAttributesDef
 */
export interface PrivateServiceSpecificationApiOperationAttributesDef {
    /**
     * API Operation name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`/service-specification). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    name?: string;
    /**
     * API Operation description; to provide a detailed information about the API Endpoint.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiOperationHttpMethodEnum}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    httpMethod: ServiceSpecificationApiOperationHttpMethodEnum;
    /**
     * Path of the API Operation.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    path: string;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    xCoordinate?: number | null;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    yCoordinate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationBodyDef
 */
export interface PrivateServiceSpecificationApiOperationBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationBodyDef
     */
    data: PrivateServiceSpecificationApiOperationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationDef
 */
export interface PrivateServiceSpecificationApiOperationDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    type?: PrivateServiceSpecificationApiOperationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationIdentifierObjectDef
 */
export interface PrivateServiceSpecificationApiOperationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkAllOfDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkAllOfDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkAttributesDef
 */
export interface PrivateServiceSpecificationApiOperationLinkAttributesDef {
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PrivateServiceSpecificationApiOperationLinkAttributesDef
     */
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PrivateServiceSpecificationApiOperationLinkAttributesDef
     */
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkBodyDef
     */
    data: PrivateServiceSpecificationApiOperationLinkDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkDef
 */
export interface PrivateServiceSpecificationApiOperationLinkDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkRelationshipsDef
 */
export interface PrivateServiceSpecificationApiOperationLinkRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsDef
     */
    outwardApiOperation: PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsDef
     */
    inwardApiOperation: PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
 */
export interface PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
     */
    data?: PrivateServiceSpecificationApiOperationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
 */
export interface PrivateServiceSpecificationApiOperationLinkUnidentifiedDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef
 */
export interface PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDefTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationDef>}
     * @memberof PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationListResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationDef>}
     * @memberof PrivateServiceSpecificationApiOperationListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationRelationshipsDef
 */
export interface PrivateServiceSpecificationApiOperationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationApiOperationRelationshipsDef
     */
    serviceSpecification: PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef
 */
export interface PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef {
    /**
     * 
     * @type {PrivateServiceSpecificationIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef
     */
    data?: PrivateServiceSpecificationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    type?: PrivateServiceSpecificationApiOperationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationAttributesDef
 */
export interface PrivateServiceSpecificationAttributesDef {
    /**
     * Service name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    name: string;
    /**
     * Service title, or customer-facing name; to provide the full name of the Service.
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    title?: string | null;
    /**
     * Service description; to provide a detailed information about the Service.
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    description?: string | null;
    /**
     * API Specification of the provided Service.
     * @type {object}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    apiSpecification?: object;
    /**
     * 
     * @type {ServiceSpecificationSecurityLevelEnum}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    securityLevel: ServiceSpecificationSecurityLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationBodyDef
 */
export interface PrivateServiceSpecificationBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationBodyDef
     */
    data: PrivateServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationDef
 */
export interface PrivateServiceSpecificationDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationDef
     */
    type?: PrivateServiceSpecificationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentAllOfDef
 */
export interface PrivateServiceSpecificationEnvironmentAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentAttributesDef}
     * @memberof PrivateServiceSpecificationEnvironmentAllOfDef
     */
    attributes?: PrivateServiceSpecificationEnvironmentAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentRelationshipsDef}
     * @memberof PrivateServiceSpecificationEnvironmentAllOfDef
     */
    relationships?: PrivateServiceSpecificationEnvironmentRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentAttributesDef
 */
export interface PrivateServiceSpecificationEnvironmentAttributesDef {
    /**
     * Service Environment name. Can be used instread of `id` to uniquely identify the Service Environment within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentAttributesDef
     */
    name: string;
    /**
     * Service Environment title, or customer-facing name; to provide the full name of the Service Environment.
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentAttributesDef
     */
    title?: string | null;
    /**
     * Service Environment description; to provide a detailed information about the Service Environment.
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentAttributesDef
     */
    description?: string | null;
    /**
     * Hostname/ IP where this Environment is available.
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentAttributesDef
     */
    host?: string | null;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentBodyDef
 */
export interface PrivateServiceSpecificationEnvironmentBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentDef}
     * @memberof PrivateServiceSpecificationEnvironmentBodyDef
     */
    data: PrivateServiceSpecificationEnvironmentDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentDef
 */
export interface PrivateServiceSpecificationEnvironmentDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentDef
     */
    type?: PrivateServiceSpecificationEnvironmentDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentAttributesDef}
     * @memberof PrivateServiceSpecificationEnvironmentDef
     */
    attributes?: PrivateServiceSpecificationEnvironmentAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentRelationshipsDef}
     * @memberof PrivateServiceSpecificationEnvironmentDef
     */
    relationships?: PrivateServiceSpecificationEnvironmentRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationEnvironmentDefTypeEnum {
    SERVICE_SPECIFICATION_ENVIRONMENTS = 'service-specification-environments'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationEnvironmentIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATION_ENVIRONMENTS = 'service-specification-environments'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentIdentifierObjectDef
 */
export interface PrivateServiceSpecificationEnvironmentIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationEnvironmentIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationEnvironmentIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATION_ENVIRONMENTS = 'service-specification-environments'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationEnvironmentListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationEnvironmentDef>}
     * @memberof PrivateServiceSpecificationEnvironmentListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationEnvironmentDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentListResponseBodyDef
 */
export interface PrivateServiceSpecificationEnvironmentListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationEnvironmentDef>}
     * @memberof PrivateServiceSpecificationEnvironmentListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationEnvironmentDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentRelationshipsDef
 */
export interface PrivateServiceSpecificationEnvironmentRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationEnvironmentRelationshipsDef
     */
    serviceSpecification: PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationEnvironmentResponseBodyDef
 */
export interface PrivateServiceSpecificationEnvironmentResponseBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentDef}
     * @memberof PrivateServiceSpecificationEnvironmentResponseBodyDef
     */
    data: PrivateServiceSpecificationEnvironmentDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationIdentifierObjectDef
 */
export interface PrivateServiceSpecificationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationDef>}
     * @memberof PrivateServiceSpecificationListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationListResponseBodyDef
 */
export interface PrivateServiceSpecificationListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationDef>}
     * @memberof PrivateServiceSpecificationListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRelationshipsDef
 */
export interface PrivateServiceSpecificationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateServiceSpecificationRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRelationshipsOwnerAccountDef
 */
export interface PrivateServiceSpecificationRelationshipsOwnerAccountDef {
    /**
     * 
     * @type {AccountIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationRelationshipsOwnerAccountDef
     */
    data?: AccountIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRequestBodyDef
 */
export interface PrivateServiceSpecificationRequestBodyDef {
    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationRequestBodyDef
     */
    data: object;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationResponseBodyDef
 */
export interface PrivateServiceSpecificationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    type?: PrivateServiceSpecificationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PublicAccountAllOfDef
 */
export interface PublicAccountAllOfDef {
    /**
     * 
     * @type {PublicAccountAttributesDef}
     * @memberof PublicAccountAllOfDef
     */
    attributes?: PublicAccountAttributesDef;
}
/**
 * 
 * @export
 * @interface PublicAccountAttributesDef
 */
export interface PublicAccountAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof PublicAccountAttributesDef
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAccountAttributesDef
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAccountAttributesDef
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface PublicAccountBodyDef
 */
export interface PublicAccountBodyDef {
    /**
     * 
     * @type {PublicAccountDef}
     * @memberof PublicAccountBodyDef
     */
    data?: PublicAccountDef;
}
/**
 * 
 * @export
 * @interface PublicAccountDef
 */
export interface PublicAccountDef {
    /**
     * 
     * @type {string}
     * @memberof PublicAccountDef
     */
    type?: PublicAccountDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicAccountDef
     */
    id?: string;
    /**
     * 
     * @type {PublicAccountAttributesDef}
     * @memberof PublicAccountDef
     */
    attributes?: PublicAccountAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicAccountDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface PublicAccountListResponseBodyAllOfDef
 */
export interface PublicAccountListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicAccountDef>}
     * @memberof PublicAccountListResponseBodyAllOfDef
     */
    data?: Array<PublicAccountDef>;
}
/**
 * 
 * @export
 * @interface PublicAccountListResponseBodyDef
 */
export interface PublicAccountListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicAccountDef>}
     * @memberof PublicAccountListResponseBodyDef
     */
    data?: Array<PublicAccountDef>;
}
/**
 * 
 * @export
 * @interface PublicAccountResponseBodyDef
 */
export interface PublicAccountResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicAccountResponseBodyDef
     */
    type?: PublicAccountResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicAccountResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicAccountAttributesDef}
     * @memberof PublicAccountResponseBodyDef
     */
    attributes?: PublicAccountAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicAccountResponseBodyDefTypeEnum {
    ACCOUNTS = 'accounts'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificatinEnvironmentListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificatinEnvironmentListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationEnvironmentDef>}
     * @memberof PublicServiceSpecificatinEnvironmentListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationEnvironmentDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificatinEnvironmentListResponseBodyDef
 */
export interface PublicServiceSpecificatinEnvironmentListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationEnvironmentDef>}
     * @memberof PublicServiceSpecificatinEnvironmentListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationEnvironmentDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationAllOfDef
 */
export interface PublicServiceSpecificationAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationAllOfDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationAllOfDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationAllOfDef
 */
export interface PublicServiceSpecificationApiOperationAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationAllOfDef
     */
    attributes?: PublicServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationAllOfDef
     */
    relationships?: PublicServiceSpecificationApiOperationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationAttributesDef
 */
export interface PublicServiceSpecificationApiOperationAttributesDef {
    /**
     * API Operation name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`/service-specification). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    name?: string;
    /**
     * API Operation description; to provide a detailed information about the API Endpoint.
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiOperationHttpMethodEnum}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    httpMethod: ServiceSpecificationApiOperationHttpMethodEnum;
    /**
     * Path of the API Operation.
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    path: string;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    xCoordinate?: number | null;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    yCoordinate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationBodyDef
 */
export interface PublicServiceSpecificationApiOperationBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationBodyDef
     */
    data: PublicServiceSpecificationApiOperationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationDef
 */
export interface PublicServiceSpecificationApiOperationDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    type?: PublicServiceSpecificationApiOperationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    attributes?: PublicServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    relationships?: PublicServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationIdentifierObjectDef
 */
export interface PublicServiceSpecificationApiOperationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectDef
     */
    type?: PublicServiceSpecificationApiOperationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationIdentifierObjectDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkAllOfDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkAllOfDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkAttributesDef
 */
export interface PublicServiceSpecificationApiOperationLinkAttributesDef {
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PublicServiceSpecificationApiOperationLinkAttributesDef
     */
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PublicServiceSpecificationApiOperationLinkAttributesDef
     */
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkDef}
     * @memberof PublicServiceSpecificationApiOperationLinkBodyDef
     */
    data: PublicServiceSpecificationApiOperationLinkDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkDef
 */
export interface PublicServiceSpecificationApiOperationLinkDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    type?: PublicServiceSpecificationApiOperationLinkDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
 */
export interface PublicServiceSpecificationApiOperationLinkIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
     */
    type?: PublicServiceSpecificationApiOperationLinkIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkIdentifierObjectDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationLinkDef>}
     * @memberof PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkListResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationLinkDef>}
     * @memberof PublicServiceSpecificationApiOperationLinkListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkRelationshipsDef
 */
export interface PublicServiceSpecificationApiOperationLinkRelationshipsDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsDef
     */
    outwardApiOperation: PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsDef
     */
    inwardApiOperation: PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
 */
export interface PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationIdentifierObjectDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
     */
    data?: PublicServiceSpecificationApiOperationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    type?: PublicServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationApiOperationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationDef>}
     * @memberof PublicServiceSpecificationApiOperationListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationListResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationDef>}
     * @memberof PublicServiceSpecificationApiOperationListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationRelationshipsDef
 */
export interface PublicServiceSpecificationApiOperationRelationshipsDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsServiceDef}
     * @memberof PublicServiceSpecificationApiOperationRelationshipsDef
     */
    service: PublicServiceSpecificationApiOperationRelationshipsServiceDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationRelationshipsServiceDef
 */
export interface PublicServiceSpecificationApiOperationRelationshipsServiceDef {
    /**
     * 
     * @type {ServiceIdentifierObjectDef}
     * @memberof PublicServiceSpecificationApiOperationRelationshipsServiceDef
     */
    data?: ServiceIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationAttributesDef
 */
export interface PublicServiceSpecificationAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    apiSpecification?: object;
    /**
     * Shows if current User is subscribed to this Specification.
     * @type {boolean}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    isSubscribedTo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    modifiedAt?: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationBodyDef
 */
export interface PublicServiceSpecificationBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationDef}
     * @memberof PublicServiceSpecificationBodyDef
     */
    data?: PublicServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationDef
 */
export interface PublicServiceSpecificationDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationDef
     */
    type?: PublicServiceSpecificationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentAllOfDef
 */
export interface PublicServiceSpecificationEnvironmentAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentAttributesDef}
     * @memberof PublicServiceSpecificationEnvironmentAllOfDef
     */
    attributes?: PublicServiceSpecificationEnvironmentAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentRelationshipsDef}
     * @memberof PublicServiceSpecificationEnvironmentAllOfDef
     */
    relationships?: PublicServiceSpecificationEnvironmentRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentAttributesDef
 */
export interface PublicServiceSpecificationEnvironmentAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentAttributesDef
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentAttributesDef
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentAttributesDef
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentAttributesDef
     */
    host: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentBodyDef
 */
export interface PublicServiceSpecificationEnvironmentBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentDef}
     * @memberof PublicServiceSpecificationEnvironmentBodyDef
     */
    data: PublicServiceSpecificationEnvironmentDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentDef
 */
export interface PublicServiceSpecificationEnvironmentDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentDef
     */
    type?: PublicServiceSpecificationEnvironmentDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentAttributesDef}
     * @memberof PublicServiceSpecificationEnvironmentDef
     */
    attributes?: PublicServiceSpecificationEnvironmentAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentRelationshipsDef}
     * @memberof PublicServiceSpecificationEnvironmentDef
     */
    relationships?: PublicServiceSpecificationEnvironmentRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationEnvironmentDefTypeEnum {
    SERVICE_ENVIRONMENTS = 'service-environments'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationEnvironmentIdentifierObjectAllOfDefTypeEnum {
    SERVICE_ENVIRONMENTS = 'service-environments'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentIdentifierObjectDef
 */
export interface PublicServiceSpecificationEnvironmentIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentIdentifierObjectDef
     */
    type?: PublicServiceSpecificationEnvironmentIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationEnvironmentIdentifierObjectDefTypeEnum {
    SERVICE_ENVIRONMENTS = 'service-environments'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentRelationshipsDef
 */
export interface PublicServiceSpecificationEnvironmentRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PublicServiceSpecificationEnvironmentRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsServiceDef}
     * @memberof PublicServiceSpecificationEnvironmentRelationshipsDef
     */
    service: PublicServiceSpecificationApiOperationRelationshipsServiceDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationEnvironmentResponseBodyDef
 */
export interface PublicServiceSpecificationEnvironmentResponseBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationEnvironmentDef}
     * @memberof PublicServiceSpecificationEnvironmentResponseBodyDef
     */
    data: PublicServiceSpecificationEnvironmentDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationDef>}
     * @memberof PublicServiceSpecificationListResponseBodyAllOfDef
     */
    data?: Array<PublicServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationListResponseBodyDef
 */
export interface PublicServiceSpecificationListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationDef>}
     * @memberof PublicServiceSpecificationListResponseBodyDef
     */
    data?: Array<PublicServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationRelationshipsDef
 */
export interface PublicServiceSpecificationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PublicServiceSpecificationRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationResponseBodyDef
 */
export interface PublicServiceSpecificationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    type?: PublicServiceSpecificationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationResponseBodyDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface ServiceIdentifierObjectAllOfDef
 */
export interface ServiceIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectAllOfDef
     */
    type?: ServiceIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceIdentifierObjectAllOfDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface ServiceIdentifierObjectDef
 */
export interface ServiceIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectDef
     */
    type?: ServiceIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceIdentifierObjectDefTypeEnum {
    SERVICES = 'services'
}

/**
 * HTTP Method of the API Operation.
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationApiOperationHttpMethodEnum {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    CONNECT = 'CONNECT',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE',
    PATCH = 'PATCH'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationApiOperationLinkPositionEnum {
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT'
}

/**
 * Defines if this Specification is publicly available for use: </br>- `PRIVATE` [default] - Service Specification is not visible to any other Account </br>- `PROTECTED` - Service Specification is only visible to the selected Accounts; see `relationships.sharedWithAccounts` </br>- `PUBLIC` - Service Specification is visible to the entire Internet 
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationSecurityLevelEnum {
    PRIVATE = 'PRIVATE',
    PROTECTED = 'PROTECTED',
    PUBLIC = 'PUBLIC'
}

/**
 * **Note:** `refreshToken` will only expire once you used them to get new tokens.
 * @export
 * @interface TokensDef
 */
export interface TokensDef {
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    tokenType?: string;
    /**
     * Defines when the `accessToken` will expire. In seconds.
     * @type {number}
     * @memberof TokensDef
     */
    expiresIn?: number;
}

/**
 * AuthorisationApi - axios parameter creator
 * @export
 */
export const AuthorisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyAuthenticatePost: async (aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-key/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "API-KEY", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorisationApi - functional programming interface
 * @export
 */
export const AuthorisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorisationApiAxiosParamCreator(configuration)
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyAuthenticatePost(aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyAuthenticatePost(aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorisationApi - factory interface
 * @export
 */
export const AuthorisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorisationApiFp(configuration)
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyAuthenticatePost(aMeta?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeyAuthenticatePost(aMeta, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiKeyAuthenticatePost operation in AuthorisationApi.
 * @export
 * @interface AuthorisationApiApiKeyAuthenticatePostRequest
 */
export interface AuthorisationApiApiKeyAuthenticatePostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof AuthorisationApiApiKeyAuthenticatePost
     */
    readonly aMeta?: string
}

/**
 * AuthorisationApi - object-oriented interface
 * @export
 * @class AuthorisationApi
 * @extends {BaseAPI}
 */
export class AuthorisationApi extends BaseAPI {
    /**
     * API Key is provided on demand
     * @summary Authenticate via API Key.
     * @param {AuthorisationApiApiKeyAuthenticatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiKeyAuthenticatePost(requestParameters: AuthorisationApiApiKeyAuthenticatePostRequest = {}, options?: any) {
        return AuthorisationApiFp(this.configuration).apiKeyAuthenticatePost(requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsumerServiceAppsApi - axios parameter creator
 * @export
 */
export const ConsumerServiceAppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Delete a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdDelete: async (consumerServiceAppId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumerServiceAppId' is not null or undefined
            assertParamExists('consumerServiceAppsConsumerServiceAppIdDelete', 'consumerServiceAppId', consumerServiceAppId)
            const localVarPath = `/consumer-service-apps/{consumer-service-app-id}`
                .replace(`{${"consumer-service-app-id"}}`, encodeURIComponent(String(consumerServiceAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Consumer Service App for the current Account.
         * @summary Get a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdGet: async (consumerServiceAppId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumerServiceAppId' is not null or undefined
            assertParamExists('consumerServiceAppsConsumerServiceAppIdGet', 'consumerServiceAppId', consumerServiceAppId)
            const localVarPath = `/consumer-service-apps/{consumer-service-app-id}`
                .replace(`{${"consumer-service-app-id"}}`, encodeURIComponent(String(consumerServiceAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Update a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdPut: async (consumerServiceAppId: string, aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumerServiceAppId' is not null or undefined
            assertParamExists('consumerServiceAppsConsumerServiceAppIdPut', 'consumerServiceAppId', consumerServiceAppId)
            const localVarPath = `/consumer-service-apps/{consumer-service-app-id}`
                .replace(`{${"consumer-service-app-id"}}`, encodeURIComponent(String(consumerServiceAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateConsumerServiceAppBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginated list of Consumer Service Apps for the current Account.
         * @summary Paginated list of Consumer Service Apps.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterOwnerAccountId] owner\&#39;s &#x60;accountId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsGet: async (aMeta?: string, filterOwnerAccountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consumer-service-apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (filterOwnerAccountId !== undefined) {
                localVarQueryParameter['filter[ownerAccountId]'] = filterOwnerAccountId;
            }

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Consumer Service App, used to define a single application (Mobile, Web) associated with an Apigale API Key. Can be used to track API usage statistics by Apps. Every created App can be associated with Service Environment(s) this App is intended to interact with.
         * @summary Create a Consumer Service App.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsPost: async (aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consumer-service-apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateConsumerServiceAppBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumerServiceAppsApi - functional programming interface
 * @export
 */
export const ConsumerServiceAppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsumerServiceAppsApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Delete a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumerServiceAppsConsumerServiceAppIdDelete(consumerServiceAppId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumerServiceAppsConsumerServiceAppIdDelete(consumerServiceAppId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a single Consumer Service App for the current Account.
         * @summary Get a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumerServiceAppsConsumerServiceAppIdGet(consumerServiceAppId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateConsumerServiceAppResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumerServiceAppsConsumerServiceAppIdGet(consumerServiceAppId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Update a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumerServiceAppsConsumerServiceAppIdPut(consumerServiceAppId: string, aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateConsumerServiceAppResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumerServiceAppsConsumerServiceAppIdPut(consumerServiceAppId, aMeta, privateConsumerServiceAppBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Paginated list of Consumer Service Apps for the current Account.
         * @summary Paginated list of Consumer Service Apps.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterOwnerAccountId] owner\&#39;s &#x60;accountId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumerServiceAppsGet(aMeta?: string, filterOwnerAccountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateConsumerServiceAppsListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumerServiceAppsGet(aMeta, filterOwnerAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Consumer Service App, used to define a single application (Mobile, Web) associated with an Apigale API Key. Can be used to track API usage statistics by Apps. Every created App can be associated with Service Environment(s) this App is intended to interact with.
         * @summary Create a Consumer Service App.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumerServiceAppsPost(aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateConsumerServiceAppResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumerServiceAppsPost(aMeta, privateConsumerServiceAppBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsumerServiceAppsApi - factory interface
 * @export
 */
export const ConsumerServiceAppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsumerServiceAppsApiFp(configuration)
    return {
        /**
         * Deletes an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Delete a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdDelete(consumerServiceAppId: string, aMeta?: string, options?: any): AxiosPromise<void> {
            return localVarFp.consumerServiceAppsConsumerServiceAppIdDelete(consumerServiceAppId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Consumer Service App for the current Account.
         * @summary Get a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdGet(consumerServiceAppId: string, aMeta?: string, options?: any): AxiosPromise<PrivateConsumerServiceAppResponseBodyDef> {
            return localVarFp.consumerServiceAppsConsumerServiceAppIdGet(consumerServiceAppId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Consumer Service App. Can be used by Consumer Service App owner.
         * @summary Update a single Consumer Service App.
         * @param {string} consumerServiceAppId Consumer Service App identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsConsumerServiceAppIdPut(consumerServiceAppId: string, aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options?: any): AxiosPromise<PrivateConsumerServiceAppResponseBodyDef> {
            return localVarFp.consumerServiceAppsConsumerServiceAppIdPut(consumerServiceAppId, aMeta, privateConsumerServiceAppBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginated list of Consumer Service Apps for the current Account.
         * @summary Paginated list of Consumer Service Apps.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterOwnerAccountId] owner\&#39;s &#x60;accountId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsGet(aMeta?: string, filterOwnerAccountId?: string, options?: any): AxiosPromise<PrivateConsumerServiceAppsListResponseBodyDef> {
            return localVarFp.consumerServiceAppsGet(aMeta, filterOwnerAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Consumer Service App, used to define a single application (Mobile, Web) associated with an Apigale API Key. Can be used to track API usage statistics by Apps. Every created App can be associated with Service Environment(s) this App is intended to interact with.
         * @summary Create a Consumer Service App.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateConsumerServiceAppBodyDef} [privateConsumerServiceAppBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerServiceAppsPost(aMeta?: string, privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef, options?: any): AxiosPromise<PrivateConsumerServiceAppResponseBodyDef> {
            return localVarFp.consumerServiceAppsPost(aMeta, privateConsumerServiceAppBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for consumerServiceAppsConsumerServiceAppIdDelete operation in ConsumerServiceAppsApi.
 * @export
 * @interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDeleteRequest
 */
export interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDeleteRequest {
    /**
     * Consumer Service App identifier
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDelete
     */
    readonly consumerServiceAppId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDelete
     */
    readonly aMeta?: string
}

/**
 * Request parameters for consumerServiceAppsConsumerServiceAppIdGet operation in ConsumerServiceAppsApi.
 * @export
 * @interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGetRequest
 */
export interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGetRequest {
    /**
     * Consumer Service App identifier
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGet
     */
    readonly consumerServiceAppId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for consumerServiceAppsConsumerServiceAppIdPut operation in ConsumerServiceAppsApi.
 * @export
 * @interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPutRequest
 */
export interface ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPutRequest {
    /**
     * Consumer Service App identifier
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPut
     */
    readonly consumerServiceAppId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateConsumerServiceAppBodyDef}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPut
     */
    readonly privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef
}

/**
 * Request parameters for consumerServiceAppsGet operation in ConsumerServiceAppsApi.
 * @export
 * @interface ConsumerServiceAppsApiConsumerServiceAppsGetRequest
 */
export interface ConsumerServiceAppsApiConsumerServiceAppsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsGet
     */
    readonly aMeta?: string

    /**
     * owner\&#39;s &#x60;accountId&#x60; to filter by.
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsGet
     */
    readonly filterOwnerAccountId?: string
}

/**
 * Request parameters for consumerServiceAppsPost operation in ConsumerServiceAppsApi.
 * @export
 * @interface ConsumerServiceAppsApiConsumerServiceAppsPostRequest
 */
export interface ConsumerServiceAppsApiConsumerServiceAppsPostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateConsumerServiceAppBodyDef}
     * @memberof ConsumerServiceAppsApiConsumerServiceAppsPost
     */
    readonly privateConsumerServiceAppBodyDef?: PrivateConsumerServiceAppBodyDef
}

/**
 * ConsumerServiceAppsApi - object-oriented interface
 * @export
 * @class ConsumerServiceAppsApi
 * @extends {BaseAPI}
 */
export class ConsumerServiceAppsApi extends BaseAPI {
    /**
     * Deletes an existing Consumer Service App. Can be used by Consumer Service App owner.
     * @summary Delete a single Consumer Service App.
     * @param {ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerServiceAppsApi
     */
    public consumerServiceAppsConsumerServiceAppIdDelete(requestParameters: ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdDeleteRequest, options?: any) {
        return ConsumerServiceAppsApiFp(this.configuration).consumerServiceAppsConsumerServiceAppIdDelete(requestParameters.consumerServiceAppId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Consumer Service App for the current Account.
     * @summary Get a single Consumer Service App.
     * @param {ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerServiceAppsApi
     */
    public consumerServiceAppsConsumerServiceAppIdGet(requestParameters: ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdGetRequest, options?: any) {
        return ConsumerServiceAppsApiFp(this.configuration).consumerServiceAppsConsumerServiceAppIdGet(requestParameters.consumerServiceAppId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Consumer Service App. Can be used by Consumer Service App owner.
     * @summary Update a single Consumer Service App.
     * @param {ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerServiceAppsApi
     */
    public consumerServiceAppsConsumerServiceAppIdPut(requestParameters: ConsumerServiceAppsApiConsumerServiceAppsConsumerServiceAppIdPutRequest, options?: any) {
        return ConsumerServiceAppsApiFp(this.configuration).consumerServiceAppsConsumerServiceAppIdPut(requestParameters.consumerServiceAppId, requestParameters.aMeta, requestParameters.privateConsumerServiceAppBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginated list of Consumer Service Apps for the current Account.
     * @summary Paginated list of Consumer Service Apps.
     * @param {ConsumerServiceAppsApiConsumerServiceAppsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerServiceAppsApi
     */
    public consumerServiceAppsGet(requestParameters: ConsumerServiceAppsApiConsumerServiceAppsGetRequest = {}, options?: any) {
        return ConsumerServiceAppsApiFp(this.configuration).consumerServiceAppsGet(requestParameters.aMeta, requestParameters.filterOwnerAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Consumer Service App, used to define a single application (Mobile, Web) associated with an Apigale API Key. Can be used to track API usage statistics by Apps. Every created App can be associated with Service Environment(s) this App is intended to interact with.
     * @summary Create a Consumer Service App.
     * @param {ConsumerServiceAppsApiConsumerServiceAppsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerServiceAppsApi
     */
    public consumerServiceAppsPost(requestParameters: ConsumerServiceAppsApiConsumerServiceAppsPostRequest = {}, options?: any) {
        return ConsumerServiceAppsApiFp(this.configuration).consumerServiceAppsPost(requestParameters.aMeta, requestParameters.privateConsumerServiceAppBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateServiceSpecificationEnvironmentsApi - axios parameter creator
 * @export
 */
export const PrivateServiceSpecificationEnvironmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Service Specification Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsGet: async (aMeta?: string, filterServiceId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specification-environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (filterServiceId !== undefined) {
                localVarQueryParameter['filter[serviceId]'] = filterServiceId;
            }

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a  Service Specification Environment, used to define a server/ host where the API service is deployed.
         * @summary Create a Service Specification Environment.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsPost: async (aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specification-environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationEnvironmentBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Delete a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete: async (serviceSpecificationEnvironmentId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationEnvironmentId' is not null or undefined
            assertParamExists('serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete', 'serviceSpecificationEnvironmentId', serviceSpecificationEnvironmentId)
            const localVarPath = `/service-specification-environments/{service-specification-environment-id}`
                .replace(`{${"service-specification-environment-id"}}`, encodeURIComponent(String(serviceSpecificationEnvironmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet: async (serviceSpecificationEnvironmentId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationEnvironmentId' is not null or undefined
            assertParamExists('serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet', 'serviceSpecificationEnvironmentId', serviceSpecificationEnvironmentId)
            const localVarPath = `/service-specification-environments/{service-specification-environment-id}`
                .replace(`{${"service-specification-environment-id"}}`, encodeURIComponent(String(serviceSpecificationEnvironmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Update a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut: async (serviceSpecificationEnvironmentId: string, aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationEnvironmentId' is not null or undefined
            assertParamExists('serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut', 'serviceSpecificationEnvironmentId', serviceSpecificationEnvironmentId)
            const localVarPath = `/service-specification-environments/{service-specification-environment-id}`
                .replace(`{${"service-specification-environment-id"}}`, encodeURIComponent(String(serviceSpecificationEnvironmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationEnvironmentBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateServiceSpecificationEnvironmentsApi - functional programming interface
 * @export
 */
export const PrivateServiceSpecificationEnvironmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateServiceSpecificationEnvironmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specification Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationEnvironmentsGet(aMeta?: string, filterServiceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationEnvironmentListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationEnvironmentsGet(aMeta, filterServiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a  Service Specification Environment, used to define a server/ host where the API service is deployed.
         * @summary Create a Service Specification Environment.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationEnvironmentsPost(aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationEnvironmentsPost(aMeta, privateServiceSpecificationEnvironmentBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Delete a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(serviceSpecificationEnvironmentId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(serviceSpecificationEnvironmentId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(serviceSpecificationEnvironmentId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(serviceSpecificationEnvironmentId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Update a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(serviceSpecificationEnvironmentId: string, aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(serviceSpecificationEnvironmentId, aMeta, privateServiceSpecificationEnvironmentBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateServiceSpecificationEnvironmentsApi - factory interface
 * @export
 */
export const PrivateServiceSpecificationEnvironmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateServiceSpecificationEnvironmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specification Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsGet(aMeta?: string, filterServiceId?: string, options?: any): AxiosPromise<PrivateServiceSpecificationEnvironmentListResponseBodyDef> {
            return localVarFp.serviceSpecificationEnvironmentsGet(aMeta, filterServiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a  Service Specification Environment, used to define a server/ host where the API service is deployed.
         * @summary Create a Service Specification Environment.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsPost(aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef> {
            return localVarFp.serviceSpecificationEnvironmentsPost(aMeta, privateServiceSpecificationEnvironmentBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Delete a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(serviceSpecificationEnvironmentId: string, aMeta?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(serviceSpecificationEnvironmentId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(serviceSpecificationEnvironmentId: string, aMeta?: string, options?: any): AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef> {
            return localVarFp.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(serviceSpecificationEnvironmentId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification Environment. Can be used by Service Specification Environment owner.
         * @summary Update a single Service Specification Environment.
         * @param {string} serviceSpecificationEnvironmentId Service Specification Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationEnvironmentBodyDef} [privateServiceSpecificationEnvironmentBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(serviceSpecificationEnvironmentId: string, aMeta?: string, privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationEnvironmentResponseBodyDef> {
            return localVarFp.serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(serviceSpecificationEnvironmentId, aMeta, privateServiceSpecificationEnvironmentBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceSpecificationEnvironmentsGet operation in PrivateServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGetRequest
 */
export interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGet
     */
    readonly aMeta?: string

    /**
     * &#x60;serviceId&#x60; to filter by.
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGet
     */
    readonly filterServiceId?: string
}

/**
 * Request parameters for serviceSpecificationEnvironmentsPost operation in PrivateServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPostRequest
 */
export interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentBodyDef}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPost
     */
    readonly privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef
}

/**
 * Request parameters for serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete operation in PrivateServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDeleteRequest
 */
export interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDeleteRequest {
    /**
     * Service Specification Environment identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete
     */
    readonly serviceSpecificationEnvironmentId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete
     */
    readonly aMeta?: string
}

/**
 * Request parameters for serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet operation in PrivateServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGetRequest
 */
export interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGetRequest {
    /**
     * Service Specification Environment identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet
     */
    readonly serviceSpecificationEnvironmentId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut operation in PrivateServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPutRequest
 */
export interface PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPutRequest {
    /**
     * Service Specification Environment identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut
     */
    readonly serviceSpecificationEnvironmentId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationEnvironmentBodyDef}
     * @memberof PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut
     */
    readonly privateServiceSpecificationEnvironmentBodyDef?: PrivateServiceSpecificationEnvironmentBodyDef
}

/**
 * PrivateServiceSpecificationEnvironmentsApi - object-oriented interface
 * @export
 * @class PrivateServiceSpecificationEnvironmentsApi
 * @extends {BaseAPI}
 */
export class PrivateServiceSpecificationEnvironmentsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Service Specification Environments.
     * @param {PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationEnvironmentsApi
     */
    public serviceSpecificationEnvironmentsGet(requestParameters: PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsGetRequest = {}, options?: any) {
        return PrivateServiceSpecificationEnvironmentsApiFp(this.configuration).serviceSpecificationEnvironmentsGet(requestParameters.aMeta, requestParameters.filterServiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a  Service Specification Environment, used to define a server/ host where the API service is deployed.
     * @summary Create a Service Specification Environment.
     * @param {PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationEnvironmentsApi
     */
    public serviceSpecificationEnvironmentsPost(requestParameters: PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsPostRequest = {}, options?: any) {
        return PrivateServiceSpecificationEnvironmentsApiFp(this.configuration).serviceSpecificationEnvironmentsPost(requestParameters.aMeta, requestParameters.privateServiceSpecificationEnvironmentBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing Service Specification Environment. Can be used by Service Specification Environment owner.
     * @summary Delete a single Service Specification Environment.
     * @param {PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationEnvironmentsApi
     */
    public serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(requestParameters: PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDeleteRequest, options?: any) {
        return PrivateServiceSpecificationEnvironmentsApiFp(this.configuration).serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdDelete(requestParameters.serviceSpecificationEnvironmentId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service Specification Environment.
     * @param {PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationEnvironmentsApi
     */
    public serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(requestParameters: PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGetRequest, options?: any) {
        return PrivateServiceSpecificationEnvironmentsApiFp(this.configuration).serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdGet(requestParameters.serviceSpecificationEnvironmentId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification Environment. Can be used by Service Specification Environment owner.
     * @summary Update a single Service Specification Environment.
     * @param {PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationEnvironmentsApi
     */
    public serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(requestParameters: PrivateServiceSpecificationEnvironmentsApiServiceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPutRequest, options?: any) {
        return PrivateServiceSpecificationEnvironmentsApiFp(this.configuration).serviceSpecificationEnvironmentsServiceSpecificationEnvironmentIdPut(requestParameters.serviceSpecificationEnvironmentId, requestParameters.aMeta, requestParameters.privateServiceSpecificationEnvironmentBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateServiceSpecificationsApi - axios parameter creator
 * @export
 */
export const PrivateServiceSpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsGet: async (aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsPost: async (aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksGet: async (serviceSpecificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationLinksGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operation-links`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksPut: async (serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationLinksPut', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operation-links`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch: async (serviceSpecificationId: string, apiOperationId: string, aMeta?: string, inlineObjectDef?: InlineObjectDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch', 'serviceSpecificationId', serviceSpecificationId)
            // verify required parameter 'apiOperationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch', 'apiOperationId', apiOperationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operations/{api-operation-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)))
                .replace(`{${"api-operation-id"}}`, encodeURIComponent(String(apiOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObjectDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsGet: async (serviceSpecificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operations`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdDelete: async (serviceSpecificationId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdDelete', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdGet: async (serviceSpecificationId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdPut: async (serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdPut', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateServiceSpecificationsApi - functional programming interface
 * @export
 */
export const PrivateServiceSpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateServiceSpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsGet(aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsGet(aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsPost(aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsPost(aMeta, privateServiceSpecificationRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId: string, apiOperationId: string, aMeta?: string, inlineObjectDef?: InlineObjectDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId, apiOperationId, aMeta, inlineObjectDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId, aMeta, privateServiceSpecificationBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateServiceSpecificationsApi - factory interface
 * @export
 */
export const PrivateServiceSpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateServiceSpecificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsGet(aMeta?: string, options?: any): AxiosPromise<PrivateServiceSpecificationListResponseBodyDef> {
            return localVarFp.serviceSpecificationsGet(aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsPost(aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsPost(aMeta, privateServiceSpecificationRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId: string, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId: string, apiOperationId: string, aMeta?: string, inlineObjectDef?: InlineObjectDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId, apiOperationId, aMeta, inlineObjectDef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId: string, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId: string, aMeta?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId: string, aMeta?: string, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId, aMeta, privateServiceSpecificationBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceSpecificationsGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for serviceSpecificationsPost operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsPostRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsPostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationRequestBodyDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsPost
     */
    readonly privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationLinksGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGet
     */
    readonly serviceSpecificationId: string
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationLinksPut operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPut
     */
    readonly serviceSpecificationId: string

    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPut
     */
    readonly privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly serviceSpecificationId: string

    /**
     * Service Specification API Operation identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly apiOperationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly aMeta?: string

    /**
     * 
     * @type {InlineObjectDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly inlineObjectDef?: InlineObjectDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationsGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGet
     */
    readonly serviceSpecificationId: string
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdDelete operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDelete
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDelete
     */
    readonly aMeta?: string
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGet
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdPut operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationBodyDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef
}

/**
 * PrivateServiceSpecificationsApi - object-oriented interface
 * @export
 * @class PrivateServiceSpecificationsApi
 * @extends {BaseAPI}
 */
export class PrivateServiceSpecificationsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Service Specifications.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsGetRequest = {}, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsGet(requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
     * @summary Create a Service Specification
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsPost(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsPostRequest = {}, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsPost(requestParameters.aMeta, requestParameters.privateServiceSpecificationRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service Specification API Operation Links.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(requestParameters.serviceSpecificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Service Specification API Operation Links.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(requestParameters.serviceSpecificationId, requestParameters.privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
     * @summary Update a single Service Specification API Operation.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(requestParameters.serviceSpecificationId, requestParameters.apiOperationId, requestParameters.aMeta, requestParameters.inlineObjectDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service Specification API Operations.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationsGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationsGet(requestParameters.serviceSpecificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing Service Specification. Can be used by Service Specification owner.
     * @summary Delete a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdDelete(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdDelete(requestParameters.serviceSpecificationId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdGet(requestParameters.serviceSpecificationId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification. Can be used by Service Specification owner.
     * @summary Update a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdPut(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdPut(requestParameters.serviceSpecificationId, requestParameters.aMeta, requestParameters.privateServiceSpecificationBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationEnvironmentsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationEnvironmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Service Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceEnvironmentsGet: async (aMeta?: string, filterServiceId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["shared_object_conditional:read", "administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (filterServiceId !== undefined) {
                localVarQueryParameter['filter[serviceId]'] = filterServiceId;
            }

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service Environment.
         * @param {string} serviceEnvironmentId Service Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceEnvironmentsServiceEnvironmentIdGet: async (serviceEnvironmentId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceEnvironmentId' is not null or undefined
            assertParamExists('serviceEnvironmentsServiceEnvironmentIdGet', 'serviceEnvironmentId', serviceEnvironmentId)
            const localVarPath = `/service-environments/{service-environment-id}`
                .replace(`{${"service-environment-id"}}`, encodeURIComponent(String(serviceEnvironmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["shared_object_conditional:read", "administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationEnvironmentsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationEnvironmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationEnvironmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceEnvironmentsGet(aMeta?: string, filterServiceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationEnvironmentResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceEnvironmentsGet(aMeta, filterServiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service Environment.
         * @param {string} serviceEnvironmentId Service Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceEnvironmentsServiceEnvironmentIdGet(serviceEnvironmentId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationEnvironmentResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceEnvironmentsServiceEnvironmentIdGet(serviceEnvironmentId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationEnvironmentsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationEnvironmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationEnvironmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Environments.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {string} [filterServiceId] &#x60;serviceId&#x60; to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceEnvironmentsGet(aMeta?: string, filterServiceId?: string, options?: any): AxiosPromise<PublicServiceSpecificationEnvironmentResponseBodyDef> {
            return localVarFp.serviceEnvironmentsGet(aMeta, filterServiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service Environment.
         * @param {string} serviceEnvironmentId Service Environment identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceEnvironmentsServiceEnvironmentIdGet(serviceEnvironmentId: string, aMeta?: string, options?: any): AxiosPromise<PublicServiceSpecificationEnvironmentResponseBodyDef> {
            return localVarFp.serviceEnvironmentsServiceEnvironmentIdGet(serviceEnvironmentId, aMeta, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceEnvironmentsGet operation in PublicServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGetRequest
 */
export interface PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGet
     */
    readonly aMeta?: string

    /**
     * &#x60;serviceId&#x60; to filter by.
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGet
     */
    readonly filterServiceId?: string
}

/**
 * Request parameters for serviceEnvironmentsServiceEnvironmentIdGet operation in PublicServiceSpecificationEnvironmentsApi.
 * @export
 * @interface PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGetRequest
 */
export interface PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGetRequest {
    /**
     * Service Environment identifier
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGet
     */
    readonly serviceEnvironmentId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGet
     */
    readonly aMeta?: string
}

/**
 * PublicServiceSpecificationEnvironmentsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationEnvironmentsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationEnvironmentsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Service Environments.
     * @param {PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationEnvironmentsApi
     */
    public serviceEnvironmentsGet(requestParameters: PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsGetRequest = {}, options?: any) {
        return PublicServiceSpecificationEnvironmentsApiFp(this.configuration).serviceEnvironmentsGet(requestParameters.aMeta, requestParameters.filterServiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service Environment.
     * @param {PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationEnvironmentsApi
     */
    public serviceEnvironmentsServiceEnvironmentIdGet(requestParameters: PublicServiceSpecificationEnvironmentsApiServiceEnvironmentsServiceEnvironmentIdGetRequest, options?: any) {
        return PublicServiceSpecificationEnvironmentsApiFp(this.configuration).serviceEnvironmentsServiceEnvironmentIdGet(requestParameters.serviceEnvironmentId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesGet: async (aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (filterIsSubscribedByCurrentAccount !== undefined) {
                localVarQueryParameter['filter[is_subscribed_by_current_account]'] = filterIsSubscribedByCurrentAccount;
            }

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationLinksGet: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiOperationLinksGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/api-operation-links`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationsGet: async (serviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiOperationsGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/api-operations`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdGet: async (serviceId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionDelete: async (serviceId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdSubscriptionDelete', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/subscription`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionPost: async (serviceId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdSubscriptionPost', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/subscription`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesGet(aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesGet(aMeta, filterIsSubscribedByCurrentAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiOperationLinksGet(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiOperationLinksGet(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiOperationsGet(serviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiOperationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiOperationsGet(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdGet(serviceId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdGet(serviceId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdSubscriptionDelete(serviceId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdSubscriptionDelete(serviceId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdSubscriptionPost(serviceId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdSubscriptionPost(serviceId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesGet(aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, options?: any): AxiosPromise<PublicServiceSpecificationListResponseBodyDef> {
            return localVarFp.servicesGet(aMeta, filterIsSubscribedByCurrentAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationLinksGet(serviceId: string, options?: any): AxiosPromise<PublicServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.servicesServiceIdApiOperationLinksGet(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationsGet(serviceId: string, options?: any): AxiosPromise<PublicServiceSpecificationApiOperationListResponseBodyDef> {
            return localVarFp.servicesServiceIdApiOperationsGet(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdGet(serviceId: string, aMeta?: string, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdGet(serviceId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionDelete(serviceId: string, aMeta?: string, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdSubscriptionDelete(serviceId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionPost(serviceId: string, aMeta?: string, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdSubscriptionPost(serviceId, aMeta, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesGetRequest
 */
export interface PublicServiceSpecificationsApiServicesGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesGet
     */
    readonly aMeta?: string

    /**
     * Filter by Services subscribed by current Account.
     * @type {boolean}
     * @memberof PublicServiceSpecificationsApiServicesGet
     */
    readonly filterIsSubscribedByCurrentAccount?: boolean
}

/**
 * Request parameters for servicesServiceIdApiOperationLinksGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdApiOperationLinksGetRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdApiOperationLinksGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdApiOperationLinksGet
     */
    readonly serviceId: string
}

/**
 * Request parameters for servicesServiceIdApiOperationsGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdApiOperationsGetRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdApiOperationsGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdApiOperationsGet
     */
    readonly serviceId: string
}

/**
 * Request parameters for servicesServiceIdGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdGetRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdGet
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for servicesServiceIdSubscriptionDelete operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdSubscriptionDeleteRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdSubscriptionDeleteRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdSubscriptionDelete
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdSubscriptionDelete
     */
    readonly aMeta?: string
}

/**
 * Request parameters for servicesServiceIdSubscriptionPost operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdSubscriptionPostRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdSubscriptionPostRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdSubscriptionPost
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdSubscriptionPost
     */
    readonly aMeta?: string
}

/**
 * PublicServiceSpecificationsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Services.
     * @param {PublicServiceSpecificationsApiServicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesGet(requestParameters: PublicServiceSpecificationsApiServicesGetRequest = {}, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesGet(requestParameters.aMeta, requestParameters.filterIsSubscribedByCurrentAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service API Operation Links.
     * @param {PublicServiceSpecificationsApiServicesServiceIdApiOperationLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdApiOperationLinksGet(requestParameters: PublicServiceSpecificationsApiServicesServiceIdApiOperationLinksGetRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdApiOperationLinksGet(requestParameters.serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service API Operations.
     * @param {PublicServiceSpecificationsApiServicesServiceIdApiOperationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdApiOperationsGet(requestParameters: PublicServiceSpecificationsApiServicesServiceIdApiOperationsGetRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdApiOperationsGet(requestParameters.serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service.
     * @param {PublicServiceSpecificationsApiServicesServiceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdGet(requestParameters: PublicServiceSpecificationsApiServicesServiceIdGetRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdGet(requestParameters.serviceId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ubsubscribe from a Service.
     * @param {PublicServiceSpecificationsApiServicesServiceIdSubscriptionDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdSubscriptionDelete(requestParameters: PublicServiceSpecificationsApiServicesServiceIdSubscriptionDeleteRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdSubscriptionDelete(requestParameters.serviceId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to a Service.
     * @param {PublicServiceSpecificationsApiServicesServiceIdSubscriptionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdSubscriptionPost(requestParameters: PublicServiceSpecificationsApiServicesServiceIdSubscriptionPostRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdSubscriptionPost(requestParameters.serviceId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }
}


